<template>
  <div class="tvmainbody">
    <b-row class="m-0" :style="pk_waiting_row">
      <b-col cols="2" class="pk_waiting_row_left m-0 p-0">
        <button type="button" class="waiting_button"><i class="el-icon-time"></i><span class="ml-3">{{ $t("Waiting") }}</span></button>
        <div v-for="(q, idx) in newlist" :key="idx" class="waiting_list">
          {{ q.num }} ({{ q.party }})
        </div>
      </b-col>
      <b-col class="pk_waiting_row_right text-center m-0 p-0">
        <div :style="backimage">&nbsp;</div>
      </b-col>
    </b-row>
    <div ref="pk_call" class="pk_call_row">
      <b-row class="m-0">
        <b-col cols="2" class="pk_call_row_left">
          <img class="call_image" src="/img/calling1.png" />
        </b-col>
        <b-col class="pk_call_row_right text-center">
          <span v-for="(q, idx) in calllist" :key="idx" class="pk_call_span">
            {{ q.num }} ({{ q.party }})
          </span>
        </b-col>
      </b-row>
    </div>
    <div ref="pk_marquee" class="pk_marquee_row">
      <div v-if="store">
        <div class="scroll-area">
          <div class="scroll">
            <!--scroll是滚动的，内部的两个元素不滚动-->
            <span class="today-scroll-content">
              {{ store.ad_txt }}
            </span>
            <span class="today-scroll-content">
              {{ store.ad_txt }}
            </span>
            <span class="today-scroll-content">
              {{ store.ad_txt }}
            </span>
            <span class="today-scroll-content">
              {{ store.ad_txt }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getStore, setStore } from "../../util/helper";
import {Table,TableColumn,Radio,RadioGroup,RadioButton,Button} from "element-ui";
import BaseButton from "../../components/BaseButton.vue";

const axios = require("axios");

export default {
  components: {
    [Button.name]: Button,
    BaseButton,
  },
  data() {
    return {
      windowHeight: 100,
      pk_waiting_row: {
        height: "500px",
        maxHeight: "500px",
        overflow: "hidden",
      },
      timer: "",
      backimage: {
        backgroundImage: "",
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",  // contain
      },
      backimage_idx: 1,

      store: false,
      newlist: [],
      calllist: [],
      passlist: [],

      lang: "en",
      user_id: 0,
      token: "",
    };
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    // console.log("mounted", this.windowHeight, window.innerHeight);
    let lang = getStore("lang");
    if (lang != "cn") {
      lang = "en";
    }
    this.setLanguage(lang);

    this.user_id = getStore("user_id");
    this.token = getStore("token");
    this.resizeBody();
    this.get_info();
  },
  computed: {},
  methods: {
    chang_image() {
      if (this.store.ad_img1 == "") {
        return;
      }
      this.backimage_idx++;
      if (this.backimage_idx > 5) {
        this.backimage_idx = 1;
      }
      this.backimage.backgroundImage = "";
      if (this.store["ad_img" + this.backimage_idx] != "") {
        this.$nextTick(() => {
          this.backimage.backgroundImage =
            "url('" +
            axios.defaults.baseURL +
            "/Api" +
            this.store["ad_img" + this.backimage_idx] +
            "')";
        });
      } else {
        this.backimage_idx = 0;
      }
    },
    stop_loading() {
      clearTimeout(this.timer);
    },
    setLanguage(lang) {
      // console.log("lang:",lang)
      this.lang = lang;
      this.$i18n.locale = lang;
      setStore("lang", lang);
    },
    resizeBody() {
      this.windowHeight = window.innerHeight;
      let h = parseInt(this.windowHeight);
      let bt_height = this.$refs.pk_marquee.clientHeight;;
      let call_height = this.$refs.pk_call.clientHeight;;

      h = h - bt_height - call_height;
      this.pk_waiting_row.height = h + "px";
      this.pk_waiting_row.maxHeight = h + "px";
    },
    get_info() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/data_info",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.store = rt.data.store;
              that.newlist = rt.data.new;
              that.calllist = rt.data.call;
              that.passlist = rt.data.pass;
              that.chang_image();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.timer = setTimeout(that.get_info, 30000);
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
  },
};
</script>
<style>
.tvmainbody {
  background-color: #bff5ff;
}
.waiting_list {
  text-align: center;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: calc(100vh * 0.05);
  color: white;
  background: green;
}
.waiting_button {
  color: green;
  background: honeydew;
  width: 100%;
  font-size: calc(100vh * 0.03);
}
.call_image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 10rem;
}
.pk_call_row {
  height: 10rem;
}
.pk_call_row_right {
  background: #29a5ec;
}
.pk_call_span {
  text-align: center;
  font-weight: bold;
  color: white;
  font-size: 7rem;
  margin-right: 4rem;
}
.pk_marquee_row {
  height: 5rem;
}
.scroll-area {
  position: relative;
  height: 5rem;
  border: 1px solid #ccc;
  background-color: #eeeeee;
  width: 100%;
  overflow: hidden;
}
.scroll {
  position: absolute;
  line-height: 5rem;
  left: 100%;
  top: 0px;
  font-weight: bold;
  font-size: 2rem;
  color: #000;
  background-color: #eeeeee;
  white-space: nowrap;
  -webkit-animation: todayScroll 20s linear infinite;
  animation: todayScroll 20s linear infinite;
}
/*只加了这个样式*/
.today-scroll-content {
  margin-right: 1.5rem;
}
@-webkit-keyframes todayScroll {
  0% {
    -webkit-transform: translate(0%);
  }
  100% {
    -webkit-transform: translate(-200%);
  }
}
@keyframes todayScroll {
  0% {
    transform: translate(0%);
  }
  100% {
    transform: translate(-200%);
  }
}
</style>

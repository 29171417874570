<template>
  <div>
    <!-- Page content -->
    <b-container class="mainbody">
      <b-row>
        <b-col>
          <b-row ref="titlerow" class="pt-2">
            <b-col>
              <span>
                <img class="logosize" src="img/brand/logo.jpg" />
              </span>
            </b-col>
            <b-col class="text-right">
              <b-button
                v-if="lang == 'en'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('cn')"
                >中文</b-button
              >
              <b-button
                v-if="lang == 'cn'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('en')"
                >English</b-button
              >
            </b-col>
          </b-row>
          <b-row
            ref="bodyrow"
            :style="bodyChangeHeight"
            class="justify-content-center"
          >
            <b-col>
              <b-alert
                variant="danger"
                fade
                :show="showDismissibleAlert"
                @dismissed="showDismissibleAlert = false"
                dismissible
              >
                {{ alert_msg }}
              </b-alert>
              <div ref="tabswidth">
                <b-tabs
                  content-class="mt-3"
                  v-model="tabIndex"
                  @input="tabChange"
                >
                  <b-tab :title="$t('Queue')" lazy active>
                    <el-table
                      :data="newlist"
                      style="width: 100%"
                      stripe
                      fit
                      size="mini"
                    >
                      <el-table-column
                        prop="num"
                        :min-width="10"
                        :label="$t('Number')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="party"
                        :min-width="10"
                        :label="$t('Party')"
                      >
                      </el-table-column>
                      <el-table-column :min-width="15" :label="$t('Time')">
                        <template v-slot="{ row }">
                          {{ row.create_tm.substring(11) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="phone"
                        :min-width="10"
                        :label="$t('Telephone')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :min-width="25"
                        :label="$t('Name')"
                      >
                      </el-table-column>
                      <el-table-column :min-width="10" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'cancel')"
                            size="small"
                          >
                            {{ $t("Cancel") }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="10" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'pass')"
                            size="small"
                          >
                            {{ $t("Pass") }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="10" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'call')"
                            size="small"
                          >
                            {{ $t("Calling") }}
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-tab>
                  <b-tab
                    :title="$t('Calling')"
                    lazy
                    :disabled="calllist.length == 0"
                  >
                    <el-table
                      :data="calllist"
                      style="width: 100%"
                      stripe
                      fit
                      size="mini"
                    >
                      <el-table-column
                        prop="num"
                        :min-width="10"
                        :label="$t('Number')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="party"
                        :min-width="10"
                        :label="$t('Party')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="table_name"
                        :min-width="10"
                        :label="$t('Table')"
                      >
                      </el-table-column>
                      <el-table-column :min-width="10" :label="$t('Time')">
                        <template v-slot="{ row }">
                          {{ row.update_tm.substring(11) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="phone"
                        :min-width="15"
                        :label="$t('Telephone')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :min-width="15"
                        :label="$t('Name')"
                      >
                      </el-table-column>
                      <el-table-column :min-width="10" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'cancel')"
                            size="small"
                          >
                            {{ $t("Cancel") }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="10" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'pass')"
                            size="small"
                          >
                            {{ $t("Pass") }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="10" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'done')"
                            size="small"
                          >
                            {{ $t("Done") }}
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-tab>
                  <b-tab
                    :title="$t('Pass')"
                    lazy
                    :disabled="passlist.length == 0"
                  >
                    <el-table
                      :data="passlist"
                      style="width: 100%"
                      stripe
                      fit
                      size="mini"
                    >
                      <el-table-column
                        prop="num"
                        :min-width="10"
                        :label="$t('Number')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="party"
                        :min-width="10"
                        :label="$t('Party')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="phone"
                        :min-width="25"
                        :label="$t('Telephone')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :min-width="25"
                        :label="$t('Name')"
                      >
                      </el-table-column>
                      <el-table-column :min-width="15" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'call')"
                            size="small"
                          >
                            {{ $t("Calling") }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="15" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            @click.native.prevent="make_active(row, 'done')"
                            size="small"
                          >
                            {{ $t("Done") }}
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-tab>

                  <b-tab :title="$t('Table')" lazy>
                    <div class="tablesdiv">
                      <div>
                        <el-radio
                          v-model="cur_floor_idx"
                          v-for="(floor, idx) in floorlist"
                          :key="idx"
                          :label="idx"
                          >{{ floor.name }}</el-radio
                        >
                        <el-button
                          type="primary"
                          size="small"
                          class="mr-3"
                          style="float: right"
                          @click="table_reset_color"
                          >{{ $t("Reset") }}</el-button
                        >
                      </div>
                      <div ref="tableImage" :style="manage_table_style">
                        <div
                          v-for="(tb, idx) in tablelist"
                          :key="idx"
                          :style="table_style(tb)"
                          @click="table_change_color(tb)"
                        >
                          {{ tb.name }}
                        </div>
                      </div>
                    </div>
                  </b-tab>

                  <b-tab :title="$t('Reserve')" lazy>
                    <div class="mb-2">
                      <el-button
                        type="primary"
                        v-for="dt in weeklist"
                        :key="dt"
                        @click.native.prevent="get_week_reservation(dt)"
                        size="small"
                      >
                        {{ dt }}
                      </el-button>
                    </div>
                    <el-table
                      :data="reservelist"
                      :default-sort = "{prop: 'rnum', order: 'ascending'}"
                      style="width: 100%"
                      stripe
                      fit
                      size="mini"
                    >
                      <el-table-column
                        sortable
                        prop="rnum"
                        :min-width="10"
                        :label="$t('Number')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="tm"
                        sortable
                        :min-width="15"
                        :label="$t('Time')"
                      >
                      </el-table-column>
                      <el-table-column
                        sortable
                        prop="number"
                        :min-width="7"
                        :label="$t('Party')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="status"
                        :min-width="7"
                        :label="$t('Status')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="phone"
                        :min-width="15"
                        :label="$t('Telephone')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :min-width="15"
                        :label="$t('Name')"
                      >
                      </el-table-column>
                      <el-table-column :min-width="10" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            :disabled="
                              row.status == 'Cancel' || row.status == 'Done'
                            "
                            @click.native.prevent="
                              make_reserve_active(row, 'Cancel')
                            "
                            size="small"
                          >
                            {{ $t("Cancel") }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="10" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            :disabled="
                              row.status == 'Cancel' ||
                              row.status == 'Message' ||
                              row.status == 'Done'
                            "
                            @click.native.prevent="
                              make_reserve_active(row, 'Message')
                            "
                            size="small"
                          >
                            {{ $t("Confirm") }}
                          </el-button>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="10" label="">
                        <template v-slot="{ row }">
                          <el-button
                            type="primary"
                            :disabled="
                              row.status == 'Cancel' || row.status == 'Done'
                            "
                            @click.native.prevent="
                              make_reserve_active(row, 'Done')
                            "
                            size="small"
                          >
                            {{ $t("Done") }}
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-tab>

                  <b-tab
                    :title="$t('History')"
                    lazy
                    :disabled="historylist.length == 0"
                  >
                    <el-table
                      :data="historylist"
                      style="width: 100%"
                      stripe
                      fit
                      size="mini"
                    >
                      <el-table-column
                        prop="num"
                        :min-width="10"
                        :label="$t('Number')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="party"
                        :min-width="5"
                        :label="$t('Party')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="table_name"
                        :min-width="5"
                        :label="$t('Table')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="phone"
                        :min-width="25"
                        :label="$t('Telephone')"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        :min-width="25"
                        :label="$t('Name')"
                      >
                      </el-table-column>
                      <el-table-column :min-width="15" :label="$t('Status')">
                        <template v-slot="{ row }">
                          <div v-if="row.status == 4">{{ $t("Cancel") }}</div>
                          <div v-if="row.status == 9">{{ $t("Done") }}</div>
                        </template>
                      </el-table-column>
                      <el-table-column :min-width="15" :label="$t('Time')">
                        <template v-slot="{ row }">
                          {{ row.create_tm }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </b-tab>

                  <b-tab :title="$t('Add Queue')" lazy>
                    <b-row>
                      <b-col cols="2"> </b-col>
                      <b-col>
                        <div v-if="queue_qnumber">
                          <b-row>
                            <b-col class="text-right" cols="5">
                              {{ $t("Number") }}
                            </b-col>
                            <b-col>
                              {{ queue_qnumber }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="text-right" cols="5">
                              {{ $t("People Ahead") }}
                            </b-col>
                            <b-col>
                              {{ queue_front_num }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="text-right" cols="5">
                              {{ $t("Telephone") }}
                            </b-col>
                            <b-col>
                              {{ queue_phone }}
                            </b-col>
                          </b-row>
                        </div>
                        <div v-else>
                          <b-form-group
                            label-for="name"
                            :label="$t('Name')"
                            label-cols="5"
                            label-align-sm="right"
                          >
                            <b-form-input
                              id="name"
                              v-model="queue_name"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label-for="phone"
                            :label="$t('Telephone')"
                            label-cols="5"
                            label-align-sm="right"
                          >
                            <b-form-input
                              id="phone"
                              v-model="queue_phone"
                              type="tel"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label-for="number"
                            :label="$t('Party')"
                            label-cols="5"
                            label-align-sm="right"
                          >
                            <b-form-input
                              id="number"
                              v-model="queue_number"
                              type="number"
                            ></b-form-input>
                          </b-form-group>
                          <b-row>
                            <b-col cols="6"> </b-col>
                            <b-col>
                              <base-button
                                class="btn btn-primary"
                                @click="submit_queue"
                                >{{ $t("Get Number") }}</base-button
                              >
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <b-col cols="4"> </b-col>
                    </b-row>
                  </b-tab>

                  <b-tab :title="$t('Add Reserve')" lazy>
                    <b-row>
                      <b-col cols="2"> </b-col>
                      <b-col>
                        <div v-if="reserve_rnumber">
                          <b-row>
                            <b-col class="text-right" cols="5">
                              {{ $t("Number") }}
                            </b-col>
                            <b-col>
                              {{ reserve_rnumber }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="text-right" cols="5">
                              {{ $t("Time") }}
                            </b-col>
                            <b-col>
                              {{ reserve_rtm }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="text-right" cols="5">
                              {{ $t("Party") }}
                            </b-col>
                            <b-col>
                              {{ reserve_number }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="text-right" cols="5">
                              {{ $t("Contact") }}
                            </b-col>
                            <b-col>
                              {{ reserve_name }}
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col class="text-right" cols="5">
                              {{ $t("Telephone") }}
                            </b-col>
                            <b-col>
                              {{ reserve_phone }}
                            </b-col>
                          </b-row>
                        </div>
                        <div v-else>
                          <b-form-group
                            label-for="reserve_name"
                            :label="$t('Name')"
                            label-cols="5"
                            label-align-sm="right"
                            class="mb-0"
                          >
                            <b-form-input
                              id="reserve_name"
                              v-model="reserve_name"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label-for="reserve_phone"
                            :label="$t('Telephone')"
                            label-cols="5"
                            label-align-sm="right"
                            class="mb-0"
                          >
                            <b-form-input
                              id="reserve_phone"
                              v-model="reserve_phone"
                              type="tel"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label-for="reserve_number"
                            :label="$t('Party')"
                            label-cols="5"
                            label-align-sm="right"
                            class="mb-0"
                          >
                            <b-form-input
                              id="reserve_number"
                              v-model="reserve_number"
                              type="number"
                              @update="get_reserve_tables"
                            ></b-form-input>
                          </b-form-group>
                          <b-form-group
                            label-for="reserve_slsdate"
                            :label="$t('Date')"
                            label-cols="5"
                            label-align-sm="right"
                            class="mb-0"
                          >
                            <b-form-select
                              v-model="reserve_slsdate"
                              :options="datelist_options"
                              @change="reserve_date"
                            >
                              <template #first>
                                <b-form-select-option value="" disabled>{{
                                  $t("Select Date")
                                }}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                          <b-form-group
                            label-for="reserve_slstime"
                            :label="$t('Time')"
                            label-cols="5"
                            label-align-sm="right"
                            class="mb-0"
                          >
                            <b-form-select
                              v-model="reserve_slstime"
                              :options="timelist_options"
                            >
                              <template #first>
                                <b-form-select-option value="" disabled>{{
                                  $t("Select Time")
                                }}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                          <b-row>
                            <b-col cols="6"> </b-col>
                            <b-col>
                              <base-button
                                class="btn btn-primary"
                                @click="submit_reserve"
                                >{{ $t("Apply") }}</base-button
                              >
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <b-col cols="4"> </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { getStore, setStore } from "../../util/helper";
import BaseButton from "../../components/BaseButton.vue";
import {
  Table,
  TableColumn,
  Radio,
  RadioGroup,
  RadioButton,
  Button,
} from "element-ui";
import VueRecaptcha from "vue-recaptcha";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [RadioButton.name]: RadioButton,
    BaseButton,
    VueRecaptcha,
  },
  data() {
    return {
      showDismissibleAlert: false,
      alert_msg: "test msg",
      windowHeight: 100,
      windowWidth: 100,
      h_table_area: 1024,
      y_table_area: 640,
      bodyChangeHeight: {
        height: "500px",
        maxHeight: "500px",
        overflow: "auto",
      },

      timer: false,

      cur_floor_idx: 0,
      cur_queue_id: 0,

      store: false,
      newlist: [],
      calllist: [],
      passlist: [],
      historylist: [],
      floorlist: [],
      reservelist: [],
      week_day: false,
      weeklist: [],

      queue_name: "",
      queue_phone: "",
      queue_number: "",
      queue_qnumber: "",
      queue_front_num: "",

      reserve_rnumber: "",
      reserve_tm: "",
      reserve_name: "",
      reserve_phone: "",
      reserve_number: "",
      reserve_slsdate: "",
      reserve_slstime: "",
      rdata: [],
      datelist: [],
      timelist: [],
      datelist_options: [],
      timelist_options: [],

      tabIndex: 0,
      lang: "en",
      user_id: 0,
      token: "",
    };
  },
  computed: {
    tablelist() {
      if (this.floorlist[this.cur_floor_idx] == undefined) {
        return [];
      }
      return this.floorlist[this.cur_floor_idx].tables;
    },
    manage_table_style() {
      let w = this.w_table_area + "px";
      let h = this.h_table_area + "px";
      if (
        this.floorlist.length <= 0 ||
        this.floorlist[this.cur_floor_idx] == undefined ||
        this.floorlist[this.cur_floor_idx].use_image != 1 ||
        this.floorlist[this.cur_floor_idx].image == "" ||
        !this.floorlist[this.cur_floor_idx].use_image
      ) {
        return {
          position: "relative",
          width: w,
          height: h,
          border: "solid 1px #12cdef",
          "border-radius": "10px",
          "background-color": "#82cdef",
        };
      } else {
        var imgurl =
          axios.defaults.baseURL +
          "/Api" +
          this.floorlist[this.cur_floor_idx].image;
        return {
          position: "relative",
          width: w,
          height: h,
          backgroundImage: `url(${imgurl})`,
          backgroundSize: this.w_table_area + "px " + this.h_table_area + "px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        };
      }
    },
  },
  mounted() {
    // console.log("mounted", this.windowHeight, window.innerHeight);
    let lang = getStore("lang");
    if (lang != "cn") {
      lang = "en";
    }
    this.setLanguage(lang);

    this.user_id = getStore("user_id");
    this.token = getStore("token");

    this.week_day = false;
    this.weeklist = [];
    var dt = new Date();
    var y, m, d, fdt;
    var tm = dt.getTime();
    for (var i = 0; i < 7; i++) {
      y = "" + dt.getFullYear();
      m = "" + (dt.getMonth() + 1);
      d = "" + dt.getDate();
      if (m.length < 2) m = "0" + m;
      if (d.length < 2) d = "0" + d;
      fdt = y + "-" + m + "-" + d;
      if (!this.week_day) this.week_day = fdt;
      this.weeklist.push(fdt);
      tm += 86400000;
      dt.setTime(tm);
    }

    this.resizeBody();
    this.get_info();
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  methods: {
    tabChange(idx) {
      if (idx == 6) {
        this.queue_name = "";
        this.queue_phone = "";
        this.queue_number = "";
        this.queue_qnumber = "";
        this.queue_front_num = "";
      } else if (idx == 7) {
        this.reserve_rnumber = "";
        this.reserve_tm = "";
        this.reserve_name = "";
        this.reserve_phone = "";
        this.reserve_number = "";
        this.reserve_slsdate = "";
        this.reserve_slstime = "";
      }
    },
    setLanguage(lang) {
      // console.log("lang:",lang)
      this.lang = lang;
      this.$i18n.locale = lang;
      setStore("lang", lang);
    },
    table_reset_color() {
      var bkcolor = "#29e029";

      if (this.floorlist[this.cur_floor_idx] == undefined) {
        return;
      }
      for (
        var i = 0;
        i < this.floorlist[this.cur_floor_idx].tables.length;
        i++
      ) {
        this.floorlist[this.cur_floor_idx].tables.bkcolor = bkcolor;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.store.id);
      bodyFormData.append("floor_id", this.floorlist[this.cur_floor_idx].id);
      bodyFormData.append("bkcolor", bkcolor);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/table_color_all",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.get_info();
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
          clearTimeout(that.timer);
        });
    },
    table_change_color(tb) {
      if (tb.bkcolor == "#29e029") {
        tb.bkcolor = "#f8533a";
      } else {
        tb.bkcolor = "#29e029";
      }

      var backtab = -1;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("id", tb.id);
      if (this.cur_queue_id > 0) {
        bodyFormData.append("queue_id", this.cur_queue_id);
        tb.bkcolor = "#f8533a";
        backtab = 0;
      }
      bodyFormData.append("bkcolor", tb.bkcolor);
      this.cur_queue_id = 0;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/table_color",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (backtab >= 0) {
                that.tabIndex = backtab;
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.get_info();
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
          clearTimeout(that.timer);
        });
    },
    table_style(tb) {
      let left = parseInt((tb.offset_x * this.w_table_area) / 1024);
      let top = parseInt((tb.offset_y * this.w_table_area) / 1024);
      let w = 80;
      let h = 60;
      let radius = "5px";
      let ptop = 12;

      if (tb.shape == "Square") {
        w = 60;
        h = 60;
      } else if (tb.shape == "Round") {
        w = 70;
        h = 70;
        radius = "50%";
        ptop = 16;
      } else if (tb.shape == "H-Rectangle") {
        w = 60;
        h = 80;
        ptop = 24;
      } else {
        // W-Rectangle
      }
      return {
        position: "absolute",
        "background-color": tb.bkcolor,
        "text-align": "center",
        "font-size": "20px",
        border: "solid 1px #000000",
        "border-radius": radius,
        top: top + "px",
        left: left + "px",
        width: w + "px",
        height: h + "px",
        "padding-top": ptop + "px",
      };
    },
    floor_change() {
      let tables = this.floorlist[this.cur_floor_idx].tables;
    },
    resizeBody() {
      this.titleHeigh = this.$refs.titlerow.clientHeight;
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;

      this.w_table_area = this.$refs.tabswidth.clientWidth;
      this.h_table_area = parseInt((640 * this.w_table_area) / 1024);

      let h = parseInt(this.windowHeight - this.titleHeigh) + "px";
      this.bodyChangeHeight.height = h;
      this.bodyChangeHeight.maxHeight = h;
    },
    make_reserve_active(queue, act) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("id", queue.id);
      bodyFormData.append("act", act);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/reserve_act",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.get_info();
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
          clearTimeout(that.timer);
        });
    },
    make_active(queue, act) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("id", queue.id);
      bodyFormData.append("act", act);
      if (act == "call") {
        this.cur_queue_id = queue.id;
        this.tabIndex = 3; // Table manage tab
        return;
      } else {
        this.cur_queue_id = 0;
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/queue_act",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.get_info();
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
          clearTimeout(that.timer);
        });
    },
    get_week_reservation(dt) {
      this.week_day = dt;
      this.get_info();
    },
    build_date_options() {
      this.datelist_options = [];
      for (var i = 0; i < this.datelist.length; i++) {
        this.datelist_options.push({
          value: this.datelist[i],
          text: this.datelist[i],
        });
      }
    },
    build_time_options() {
      this.timelist_options = [];
      if (
        this.timelist == undefined ||
        !this.timelist ||
        Object.keys(this.timelist).length === 0
      ) {
        this.timelist_options.push({
          value: "",
          text: this.$t("No Options"),
          disabled: true,
        });
      } else {
        for (var key in this.timelist) {
          this.timelist_options.push({ value: this.timelist[key], text: key });
        }
      }
    },
    reserve_date() {
      if (this.rdata[this.reserve_slsdate] != undefined) {
        this.timelist = this.rdata[this.reserve_slsdate];
        this.build_time_options();
      } else {
        this.timelist = [];
      }
    },
    get_reserve_tables() {
      this.datelist_options = [];
      this.timelist_options = [];
      if (this.reserve_name.length <= 0) {
        alert("Name is Require");
        return;
      }
      if (this.reserve_phone.length < 10) {
        alert("Phone Number is Require");
        return;
      }
      if (this.reserve_number.length <= 0) {
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.store.id);
      bodyFormData.append("name", this.reserve_name);
      bodyFormData.append("phone", this.reserve_phone);
      bodyFormData.append("number", this.reserve_number);
      bodyFormData.append("from_store", 1);

      var that = this;
      this.showloading = true;
      axios({
        method: "post",
        url: "/Api/Store/get_tables",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.datelist = rt.data.datelist;
              that.build_date_options();
              that.rdata = rt.data;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          that.$nextTick(() => {
            that.showloading = false;
          });
        })
        .catch(function (error) {
          // console.log(error);
          that.showloading = false;
        });
    },
    submit_reserve() {
      if (this.reserve_name.length <= 0) {
        alert("Name is Require");
        return;
      }
      if (this.reserve_phone.length < 10) {
        alert("Phone Number is Require");
        return;
      }
      if (this.reserve_number.length <= 0) {
        alert("Number of Party is Require");
        return;
      }
      if (this.reserve_slsdate == "") {
        alert("Date is Require");
        return;
      }
      if (this.reserve_slstime == "") {
        alert("Time is Require");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("name", this.reserve_name);
      bodyFormData.append("phone", this.reserve_phone);
      bodyFormData.append("number", this.reserve_number);
      bodyFormData.append("tm", this.reserve_slsdate);
      bodyFormData.append("rid", this.reserve_slstime.rid);
      bodyFormData.append("tname", this.reserve_slstime.name);
      bodyFormData.append("lang", "en");

      var that = this;
      this.showloading = true;
      axios({
        method: "post",
        url: "/Api/Store/store_hold",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.reserve_rnumber = rt.data.rnumber;
              that.reserve_rtm = rt.data.tm;
              that.get_info();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          that.showloading = false;
        })
        .catch(function (error) {
          // console.log(error);
          that.showloading = false;
        });
    },
    submit_queue() {
      if (this.queue_name.length <= 0) {
        alert("Name is Require");
        return;
      }
      if (this.queue_phone.length < 10) {
        alert("Phone Number is Require");
        return;
      }
      if (this.queue_number.length <= 0) {
        alert("Number of Party is Require");
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("name", this.queue_name);
      bodyFormData.append("phone", this.queue_phone);
      bodyFormData.append("number", this.queue_number);
      bodyFormData.append("lang", "en");

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/store_queue",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.queue_qnumber = rt.data.qnumber;
              that.queue_front_num = rt.data.front_num;
              that.get_info();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    get_info() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("dt", this.week_day);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/data_info",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.store = rt.data.store;
              that.newlist = rt.data.new;
              that.calllist = rt.data.call;
              that.passlist = rt.data.pass;
              that.historylist = rt.data.history;
              that.floorlist = rt.data.floor;
              that.reservelist = rt.data.reserve;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
            that.timer = setTimeout(that.get_info, 30000);
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
          clearTimeout(that.timer);
        });
    },
  },
};
</script>
<style>
.logosize {
  width: 5rem;
}
.mainbody {
  background-color: #11cdef;
}
.done_store {
  font-weight: bold;
  font-size: 1.5rem;
}
.active_button {
  float: right;
}
.language {
  width: 5rem;
}
body {
  background-color: #11cdef;
}
</style>

<template>
  <div>
    <!-- Page content -->
    <b-container class="mainbody">
      <b-row ref="titlerow">
        <b-col>
          <b-row class="pt-2">
            <b-col>
              <span>
                <img class="logosize" src="img/brand/logo.jpg" />
              </span>
            </b-col>
            <b-col class="text-right">
              <b-button
                v-if="lang == 'en'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('cn')"
                >中文</b-button
              >
              <b-button
                v-if="lang == 'cn'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('en')"
                >English</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        ref="bodyrow"
        :style="bodyChangeHeight"
        class="justify-content-center"
      >
        <b-col>
          <b-alert
            variant="danger"
            fade
            :show="showDismissibleAlert"
            @dismissed="showDismissibleAlert=false"
            dismissible
          >
            {{ alert_msg }}
          </b-alert>
          <b-card no-body class="bg-secondary border-0 mt-3">
            <b-card-body class="px-lg-5 py-lg-5 text-center">
              <div class="text-center mb-4 done_store">
                {{ $t("Wecome to") }} POS KING
              </div>
              <b-form-group
                label-for="store"
                :label="$t('Store')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-input id="store" v-model="store" type="number"></b-form-input>
              </b-form-group>
              <b-form-group
                label-for="password"
                :label="$t('Password')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-input id="phone" v-model="password" type="password" autocomplete="new-password"></b-form-input>
              </b-form-group>
              <b-form-group
                label-for="rtype"
                :label="$t('Type')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-select id="rtype" v-model="rtype">
                  <b-form-select-option value="linemg">{{ $t('Queue Management') }}</b-form-select-option>
                  <b-form-select-option value="linetv">{{ $t('Queue Display') }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group
                label-for="device"
                :label="$t('Device')"
                label-cols="4"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-select id="device" v-model="device">
                  <b-form-select-option value="1">#1</b-form-select-option>
                  <b-form-select-option value="2">#2</b-form-select-option>
                  <b-form-select-option value="3">#3</b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group label="" label-cols-sm="4">
                <vue-recaptcha
                  ref="recaptcha"
                  sitekey="6LfWllwaAAAAAA4uDzuMzV_bu7xdGSHgaMXuH7P4"
                  style="transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;"
                  :loadRecaptchaScript="true"
                  @verify="onCaptchaVerified"
                  @expired="onCaptchaExpired"
                ></vue-recaptcha>
              </b-form-group>
              <base-button
                class="btn btn-primary"
                :disabled="disablesubmit"
                @click="submit_queue"
                >{{ $t("Submit") }}</base-button
              >
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { getStore, setStore } from "../../util/helper";
import BaseButton from "../../components/BaseButton.vue";
import VueRecaptcha from "vue-recaptcha";

const axios = require("axios");

export default {
  components: {
    BaseButton,
    VueRecaptcha,
  },
  data() {
    return {
      lang: "en",
      showDismissibleAlert: false,
      alert_msg: "test msg",
      errorModel: false,
      disablesubmit: true, 
      windowHeight: 100,
      titleHeigh: 100,
      bodyChangeHeight: { height: "500px" },

      store: "",
      password: "",
      rtype: "linemg",
      device: "1",

      recaptcha_token: "",

      user_id: 0,
      token: "",
    };
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    // console.log("mounted", this.windowHeight, window.innerHeight);
    let lang = getStore("lang");
    if (lang != "cn") {
      lang = "en";
    }
    this.setLanguage(lang);

    setStore("token", "");
    setStore("user_id", 0);
    this.resizeBody();
  },
  methods: {
    onCaptchaVerified(recaptchaToken) {
      // console.log("Enable submit:",recaptchaToken);
      this.recaptcha_token = recaptchaToken;
      this.disablesubmit = false;
    },
    onCaptchaExpired() {
      if (this.disablesubmit) {
        this.$refs.recaptcha.reset();
      }
    },
    setLanguage(lang) {
      // console.log("lang:",lang)
      this.lang = lang;
      this.$i18n.locale = lang;
      setStore("lang", lang);
    },
    resizeBody() {
      this.titleHeigh = this.$refs.titlerow.clientHeight;
      this.windowHeight = window.innerHeight;
      let h = parseInt(this.windowHeight - this.titleHeigh) + "px";
      this.bodyChangeHeight.height = h;
    },
    submit_queue() {
      var bodyFormData = new FormData();
      bodyFormData.append("store_id", this.store);
      bodyFormData.append("passcode", this.password);
      bodyFormData.append("rtype", this.rtype); // linemg/linetv
      bodyFormData.append("device", this.device);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/login",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              setStore("token", rt.data.token);
              setStore("user_id", rt.data.user_id);
              that.$router.push("/"+that.rtype);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          ;// console.log(error);
        });
    },
  },
};
</script>
<style>
.logosize {
  width: 5rem;
}
.mainbody {
  background-color: #11cdef;
}
.done_store {
  font-weight: bold;
  font-size: 1.5rem;
}
.language {
  width: 5rem;
}
</style>

<template>
  <div>
    <!-- Page content -->
    <b-container class="mainbody">
      <b-row ref="titlerow">
        <b-col>
          <b-row class="pt-2">
            <b-col>
              <span>
                <img class="logosize" src="img/brand/logo.jpg" />
              </span>
            </b-col>
            <b-col class="text-right">
              <b-button
                v-if="lang == 'en'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('cn')"
                >中文</b-button
              >
              <b-button
                v-if="lang == 'cn'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('en')"
                >English</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        ref="bodyresult"
        v-if="qnumber"
        :style="bodyChangeHeight"
        class="justify-content-center"
      >
        <b-col>
          <b-card no-body class="bg-secondary border-0 mt-3">
            <b-card-body>
              <div class="text-center mb-4 done_store">
                {{ $t("Wecome to") }} {{ store.name }}
              </div>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Number") }}
                </b-col>
                <b-col>
                  {{ qnumber }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Party") }}
                </b-col>
                <b-col>
                  {{ number }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("People Ahead") }}
                </b-col>
                <b-col>
                  {{ front_num }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Telephone") }}
                </b-col>
                <b-col>
                  {{ phone }}
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        ref="bodyrow"
        v-else
        :style="bodyChangeHeight"
        class="justify-content-center"
      >
        <b-col>
          <b-alert
            variant="danger"
            fade
            :show="showDismissibleAlert"
            @dismissed="showDismissibleAlert=false"
            dismissible
          >
            {{ alert_msg }}
          </b-alert>
          <b-card v-if="store.lineup=='1'" no-body class="bg-secondary border-0 mt-3">
            <b-card-body v-if="store" class="px-lg-5 py-lg-5 text-center">
              <div class="text-center mb-4 done_store">
                {{ $t("Wecome to") }} {{ store.name }}
              </div>
              <b-form-group
                label-for="name"
                :label="$t('Name')"
                label-cols="5"
                label-align-sm="right"
              >
                <b-form-input id="name" v-model="name"></b-form-input>
              </b-form-group>
              <b-form-group
                label-for="phone"
                :label="$t('Telephone')"
                label-cols="5"
                label-align-sm="right"
              >
                <b-form-input id="phone" v-model="phone" type="tel"></b-form-input>
              </b-form-group>
              <b-form-group
                label-for="number"
                :label="$t('Party')"
                label-cols="5"
                label-align-sm="right"
              >
                <b-form-input id="number" v-model="number" type="number"></b-form-input>
              </b-form-group>
              <b-form-group
                label-for="verify_code"
                :label="$t('Verify Code')"
                label-cols="5"
                label-align-sm="right"
                class="mb-0"
              >
                <b-form-input id="verify_code" v-model="verifycode" type="text"></b-form-input>
              </b-form-group>
              <div class="mt-1 mb-3 text-right">
                <img :src="verifyimgdata" class="verifyimg" />
                <el-button type="primary" class="ml-1" icon="el-icon-refresh" circle @click="reload_image"></el-button>
              </div>
              <base-button
                class="btn btn-primary"
                @click="submit_queue"
                >{{ $t("Get Number") }}</base-button
              >
            </b-card-body>
            <div v-else class="text-center">
              {{ $t("Unknown Store") }}
            </div>
          </b-card>
          <b-card v-else no-body class="bg-secondary border-0 mt-3">
            <b-card-body class="px-lg-5 py-lg-5 text-center">
              <div class="text-center mb-4 done_store">
                {{ store.name }} {{ $t("Queue Closed") }}
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { getStore, setStore } from "../../util/helper";
import {Button} from "element-ui";
import BaseButton from "../../components/BaseButton.vue";

const axios = require("axios");

export default {
  components: {
    [Button.name]: Button,
    BaseButton,
  },
  data() {
    return {
      lang: "en",
      showDismissibleAlert: false,
      alert_msg: "test msg",
      errorModel: false,
      windowHeight: 100,
      titleHeigh: 100,
      bodyChangeHeight: { height: "500px" },

      store: false,

      recaptcha_token: "",

      name: "",
      phone: "",
      number: "",

      qnumber: false,
      front_num: 4,
      verifyimgdata: null,
      verifycode: '',

      user_id: 0,
      token: "",
    };
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    // console.log("mounted", this.windowHeight, window.innerHeight);
    let lang = getStore("lang");
    if (lang != "cn") {
      lang = "en";
    }
    this.setLanguage(lang);
    this.init();
  },
  methods: {
    setLanguage(lang) {
      // console.log("lang:",lang)
      this.lang = lang;
      this.$i18n.locale = lang;
      setStore("lang", lang);
    },
    resizeBody() {
      this.titleHeigh = this.$refs.titlerow.clientHeight;
      this.windowHeight = window.innerHeight;
      let h = parseInt(this.windowHeight - this.titleHeigh) + "px";
      this.bodyChangeHeight.height = h;
    },
    reload_image() {
      var bodyFormData = new FormData();
      bodyFormData.append("store_id", this.store.id);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/vimage",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.verifyimgdata = rt.data.verifyimg
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          ;// console.log(error);
        });
    },
    init() {
      let store = this.$route.query.s;
      let k = this.$route.query.k;
      this.resizeBody();
      if (store == undefined) {
        this.show_error = 1;
        this.errorModel = true;
        return;
      }

      let user_id = getStore("user_id");

      var bodyFormData = new FormData();
      bodyFormData.append("store", store);
      bodyFormData.append("key", k);
      bodyFormData.append("user_id", user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.store = rt.data.store;
              that.token = rt.data.token;
              that.user_id = rt.data.user_id;
              that.verifyimgdata = rt.data.verifyimg
              setStore("token", rt.data.token);
              setStore("user_id", rt.data.user_id);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          ;// console.log(error);
        });
    },
    submit_queue() {
      if (this.name.length <= 0) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Name is Require";
        return;
      }
      if (this.phone.length < 10) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Phone Number is Require";
        return;
      }
      if (this.number.length <= 0) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Number of Party is Require";
        return;
      }
      if (this.verifycode.length != 4) {
        this.showDismissibleAlert = true;
        this.alert_msg = "Verify Code is Wrong";
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("store_id", this.store.id);
      bodyFormData.append("name", this.name);
      bodyFormData.append("phone", this.phone);
      bodyFormData.append("number", this.number);
      bodyFormData.append("lang", this.lang);
      bodyFormData.append("verifycode", this.verifycode);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/get_queue",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.qnumber = rt.data.qnumber;
              that.number = rt.data.party;
              that.phone = rt.data.phone;
              that.front_num = rt.data.front_num;
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          ;// console.log(error);
        });
    },
  },
};
</script>
<style>
.logosize {
  width: 5rem;
}
.mainbody {
  background-color: #11cdef;
}
.done_store {
  font-weight: bold;
  font-size: 1.5rem;
}
.verifyimg {
  height: 2rem;
}
.language {
  width: 5rem;
}
</style>

<template>
  <div class="full-screen bg-default">
    <div class="nav auth_nav">
      <b-container>
        <a href="#">
          <span>
            <img src="img/brand/logo.jpg" />
          </span>
        </a>
      </b-container>
    </div>
    <div class="main-content">
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <footer class="py-2" id="footer-main">
      <b-container>
        Copyright © 2019-{{year}} POSKING Inc. All rights reserved.
      </b-container>
    </footer>
  </div>
</template>
<script>
import { BaseNav } from "@/components";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    BaseNav,
    ZoomCenterTransition
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black"
    }
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page"
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    }
  },
  methods: {},
  beforeDestroy() {}
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
html,
body {
  height: 100%;
  width: 100%;
}

.full-screen {
  height: 100%;
  width: 100%;
}

.auth_nav {
  height: 70px;
  background-color: white;
  position: relative;
  padding-top: 20px;
  .logo {
    position: relative;
  }
  img {
    width: 82px;
    height: 26px;
  }
  span {
    font-weight: 300;
    font-size: 1em;
    color: rgb(120, 120, 120);
  }
}

.login-nav .navbar-toggler {
  display: none;
}

#footer-main {
  position: fixed;
  bottom: 0px;
  color: white;
  font-size: .6rem;
  height: 30px;
  text-align: center;
  width: 100%;
  background: #505050;
  div {
    width: 100%;
    text-align: right;
  }
}
</style>

<template>
  <div>
    <!-- Page content -->
    <b-container class="mainbody">
      <b-alert
        variant="danger"
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert=false"
        dismissible
      >
        {{ alert_msg }}
      </b-alert>
      <b-row ref="titlerow">
        <b-col>
          <b-row class="pt-2">
            <b-col>
              <span>
                <img class="logosize" src="img/brand/logo.jpg" />
              </span>
            </b-col>
            <b-col class="text-right">
              <b-button
                v-if="lang == 'en'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('cn')"
                >中文</b-button
              >
              <b-button
                v-if="lang == 'cn'"
                class="language"
                size="sm"
                variant="primary"
                @click="setLanguage('en')"
                >English</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        ref="bodyresult"
        v-if="qnumber"
        :style="bodyChangeHeight"
        class="justify-content-center"
      >
        <b-col>
          <b-card no-body class="bg-secondary border-0 mt-3">
            <b-card-body>
              <div class="text-center mb-4 done_store">
                {{ $t("Wecome to") }} {{ store_name }}
              </div>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Number") }}
                </b-col>
                <b-col>
                  {{ qnumber }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("Party") }}
                </b-col>
                <b-col>
                  {{ number }}
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right" cols="5">
                  {{ $t("People Ahead") }}
                </b-col>
                <b-col>
                  {{ front_num }}
                </b-col>
              </b-row>
              <b-row v-if="status_str">
                <b-col class="text-right" cols="5">
                  {{ $t("Status") }}
                </b-col>
                <b-col>
                  <span style="font-weight: bold; color: #a95329;">{{ status_str }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-center mt-6">
                  <b-button variant="primary" @click="getStatus">{{ $t('Check Current Status') }}</b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { getStore, setStore } from "../../util/helper";
import {Button} from "element-ui";
import BaseButton from "../../components/BaseButton.vue";

const axios = require("axios");

export default {
  components: {
    [Button.name]: Button,
    BaseButton,
  },
  data() {
    return {
      lang: "en",
      showDismissibleAlert: false,
      alert_msg: "test msg",
      windowHeight: 100,
      titleHeigh: 100,
      bodyChangeHeight: { height: "500px" },

      nowtm: 0,
      s: null,
      q: null,
      d: null,
      k: null,

      store_name: "",
      number: "",
      qnumber: false,
      front_num: 4,
      status_str: null,
      status: 1, // 0: Open; 1: New; 2: Call; 3: Pass; 4: Cancel; 9: Done
    };
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    // console.log("mounted", this.windowHeight, window.innerHeight);
    let lang = getStore("lang");
    if (lang != "cn") {
      lang = "en";
    }
    this.setLanguage(lang);
    this.init();
  },
  methods: {
    setLanguage(lang) {
      // console.log("lang:",lang)
      this.lang = lang;
      this.$i18n.locale = lang;
      setStore("lang", lang);
    },
    resizeBody() {
      this.titleHeigh = this.$refs.titlerow.clientHeight;
      this.windowHeight = window.innerHeight;
      let h = parseInt(this.windowHeight - this.titleHeigh) + "px";
      this.bodyChangeHeight.height = h;
    },
    init() {
      let store = this.$route.query.s;
      let q = this.$route.query.q;
      let d = this.$route.query.d;
      let k = this.$route.query.k;
      this.resizeBody();
      if ((store == undefined) || (q == undefined) || (d == undefined) || (k == undefined)) {
        this.alert_msg = "Unknown parameter please contact admin";
        this.showDismissibleAlert = true;
        return;
      }
      this.s = store;
      this.q = q;
      this.d = d;
      this.k = k;
      this.getStatus();
    },
    getStatus() {
      var dt = new Date();
      var nowtm = dt.getTime();
      if ((nowtm - this.nowtm) > 15000) {
        // more than 15's
        this.nowtm = nowtm
      } else {
        return;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("s", this.s);
      bodyFormData.append("q", this.q);
      bodyFormData.append("d", this.d);
      bodyFormData.append("k", this.k);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Store/queue_status",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.store_name = rt.data.store_name;
              that.number = rt.data.number;
              that.qnumber = rt.data.qnumber;
              that.front_num = rt.data.front_num;
              that.status = rt.data.status; // 0: Open; 1: New; 2: Call; 3: Pass; 4: Cancel; 9: Done
              that.status_str = null;
              if (that.status == 2) {
                that.status_str = that.$t('Calling');
              } else if (that.status == 3) {
                that.status_str = that.$t('Pass');
              } else if (that.status == 4) {
                that.status_str = that.$t('Cancel');
              } else if (that.status == 9) {
                that.status_str = that.$t('Done');
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          ;// console.log(error);
        });
    },
  },
};
</script>
<style>
.logosize {
  width: 5rem;
}
.mainbody {
  background-color: #11cdef;
}
.done_store {
  font-weight: bold;
  font-size: 1.5rem;
}
.verifyimg {
  height: 2rem;
}
.language {
  width: 5rem;
}
</style>
